import { Launch } from '@styled-icons/material/Launch';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { Button } from '@naf/button';
import { useHistory, useLocation } from 'react-router';
import { ExternalLinkType } from '../../../../../../types/externalLinkType';
import { CtaInternalLinkType } from '../../../../../../types/internalLinkType';
import useSelector from '../../../../redux/typedHooks';
import { useGTMDataLayer } from '../../../../hooks/useGTMDataLayer';
import { isInternalLink } from '../../../../lib/isInternalLink';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { getHostname } from '../../../../lib/getHostname';

export const SubHeroButtons = ({
  externalLink,
  internalLink,
}: {
  externalLink: ExternalLinkType;
  internalLink: CtaInternalLinkType;
}) => {
  const baseUrl = useSelector((state) => state.application.baseUrl);
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const internalItemUrl = useDocumentUrlWithNode(internalLink.referenceComponent);

  const handleExternalTracking = () => {
    const urlObject = new URL(externalLink.href);
    dataLayer?.push({
      event: 'cta_click',
      cta_text: externalLink.text,
      cta_location: location.pathname,
      cta_url: externalLink.href,
      cta_hostname: urlObject.hostname,
      ctaPosition: 'subHero',
    });
  };

  const handleInternalTracking = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: internalLink.text,
      cta_location: location.pathname,
      cta_url: internalItemUrl,
      cta_hostname: getHostname(baseUrl),
      ctaPosition: 'subHero',
    });
  };

  const { isInternal, relativeLink } = isInternalLink(baseUrl, externalLink.href);

  return (
    <ButtonWrapper>
      {externalLink && (
        <StyledButton
          href={externalLink.link}
          size="large"
          onClick={() => {
            handleExternalTracking();
            if (!isInternal) {
              window.open(externalLink.href);
            } else {
              history.push(relativeLink);
            }
          }}
        >
          {externalLink.text}
          <Launch
            size={16}
            style={{
              marginLeft: '16px',
            }}
          />
        </StyledButton>
      )}
      {internalLink && (
        <StyledButton
          variant="outline"
          onClick={() => {
            handleInternalTracking();
            history.push(internalItemUrl);
          }}
          size="large"
        >
          {internalLink.text}
        </StyledButton>
      )}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  margin-bottom: 80px;
  @media (max-width: ${breakpoints.l}) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 40px;
  @media (max-width: ${breakpoints.l}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
`;
